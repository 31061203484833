import { Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const InformationEn = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Background>
            <Container>
                <BlurContainer>
                    <ContentInfo>
                        <Typography color={'secondary'} fontSize={isMobile ? '36px' : '54px'} fontWeight={'bold'}>1:200</Typography>
                        <Typography color={'secondary'} fontSize={isMobile ? '18px' : '24px'}>Leverage</Typography>
                    </ContentInfo>
                    <ContentInfo>
                        <Typography color={'secondary'} fontSize={isMobile ? '36px' : '54px'} fontWeight={'bold'}>100%</Typography>
                        <Typography color={'secondary'} fontSize={isMobile ? '18px' : '24px'}>Negative Balance Protection</Typography>
                    </ContentInfo>
                    <ContentInfo>
                        <Typography color={'secondary'} fontSize={isMobile ? '36px' : '54px'} fontWeight={'bold'}>+200</Typography>
                        <Typography color={'secondary'} fontSize={isMobile ? '18px' : '24px'}>Instruments</Typography>
                    </ContentInfo>
                </BlurContainer>
            </Container>
        </Background>
    )
}

export default InformationEn;

const Background = styled.div`
    padding: 50px 0;
    height: auto;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding-bottom: 2rem;
    }
`;

const BlurContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.2); /* Transparent background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari compatibility */
    padding: 20px;
    width: calc(100% - 40px); 
    height: 156px; 
    border-radius: 25px;
    border: 1px solid #00FFE0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

const ContentInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
