import { Container, Button, IconButton, Box, useMediaQuery, MenuItem } from '@mui/material';
import { HashLink as RouterLink } from 'react-router-hash-link';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import styled from 'styled-components';
import esFlag from '../../assets/flags/spanish.png';
import enFlag from '../../assets/flags/english.png';

const Navbar = ({ setLanguage }) => {
    const [language, setLocalLanguage] = useState('Español');
    const texts = {
        Español: {
            howItWorks: 'Cómo funciona',
            accountType: 'Tipo de cuenta',
            benefits: 'Beneficios',
            traders: 'cTraders',
            signIn: 'INICIAR SESIÓN',
            register: 'REGISTRARSE'
        },
        English: {
            howItWorks: 'How it works',
            accountType: 'Account Type',
            benefits: 'Benefits',
            traders: 'cTraders',
            signIn: 'SIGN IN',
            register: 'REGISTER'
        }
    };

    const handleLanguageChange = (lang) => {
        setLocalLanguage(lang);
        setLanguage(lang);
        setDropdownOpen(false);
    };

    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    const styles = isMobile ? {
        height: 18,
        position: "absolute",
        top: '27px',
        left: '18px'
    } : {
        display: 'none',
    }

    const styles2 = isMobile ? {
        height: 18,
        position: "absolute",
        top: '27px',
        left: '18px'
    } : {
        display: 'flex',
    }

    return (
        <Content>
            <ContainerCustom>
                <RouterLink smooth to="/">
                    <Box
                        component="img"
                        sx={styles}
                        alt={'Deel Markets'}
                        src={'assets/images/logo_h.png'}
                    />
                </RouterLink>
                <List menuOpen={menuOpen}>
                    <Items menuOpen={menuOpen}>
                        <RouterLink smooth to="/">
                            <Box
                                component="img"
                                sx={styles2}
                                alt={'Deel Markets'}
                                src={'assets/images/logo_h.png'}
                            />
                        </RouterLink>
                        <StyledLink smooth to="/#Como-funciona" color={'secondary'} underline="none" onClick={() => toggleMenu()}>{texts[language].howItWorks}</StyledLink>
                        <StyledLink smooth to="/#Tipo-de-cuenta" color={'secondary'} underline="none" onClick={() => toggleMenu()}>{texts[language].accountType}</StyledLink>
                        <StyledLink smooth to="/#Beneficios" color={'secondary'} underline="none" onClick={() => toggleMenu()}>{texts[language].benefits}</StyledLink>
                        <StyledLink smooth to="/#CTraders" color={'secondary'} underline="none" onClick={() => toggleMenu()}>{texts[language].traders}</StyledLink>
                    </Items>
                    <Buttons menuOpen={menuOpen}>
                        <Button variant='outlined'>{texts[language].signIn}</Button>
                        <Button variant='contained'>{texts[language].register}</Button>
                    </Buttons>
                </List>
                <LanguageButtonContainer>
                    <SelectedLanguage onClick={toggleDropdown}>
                        <img src={language === 'Español' ? esFlag : enFlag} alt={language} style={{ width: '20px', marginRight: '10px' }} />
                    </SelectedLanguage>
                    {dropdownOpen && (
                        <DropdownMenu>
                            <MenuItem onClick={() => handleLanguageChange('Español')}>
                                <img src={esFlag} alt="Español" style={{ width: '20px', marginRight: '10px' }} />
                                Español
                            </MenuItem>
                            <MenuItem onClick={() => handleLanguageChange('English')}>
                                <img src={enFlag} alt="English" style={{ width: '20px', marginRight: '10px' }} />
                                English
                            </MenuItem>
                        </DropdownMenu>
                    )}
                </LanguageButtonContainer>
                <HamburgerButton onClick={toggleMenu}>
                    {menuOpen ? <CloseIcon color={'secondary'} /> : <MenuIcon color={'secondary'} />}
                </HamburgerButton>

            </ContainerCustom>
        </Content>
    );
};

export default Navbar;

const Content = styled.nav`
    width: 100%;
    height: 90px;
    background-color: #0B1119;
    position: relative;
    z-index: 9999 !important;
    @media (max-width: 768px) {
        height: 70px;
    }
`;

const ContainerCustom = styled(Container)`
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    @media (max-width: 768px) {
        justify-content: flex-end;
        gap: 12px;
    }
`;

const Items = styled.div`
    display: flex;
    gap: 16px;
    /* width: 100%; */
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-size: 18px;
    }
`

const List = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 74%;
        height: 100%;
        background-color: #0B1119;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        transform: ${props => (props.menuOpen ? 'translateX(0)' : 'translateX(-100%)')};
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }
`;

const Buttons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        margin-top: 1rem; 
        button {
            width: 100%;
        }
    }
`;

const HamburgerButton = styled(IconButton)`
    display: none !important;
    @media (max-width: 768px) {
        display: block !important;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1001;
    }
`;

const LanguageButtonContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    gap: 8px;
    z-index: 1002;
    border-radius: 8px;
`;

const SelectedLanguage = styled.div`
    display: flex;
    align-items: center;
    z-index: 1002;
    margin-left: 15px;
    cursor: pointer;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    background-color: #0B1119;
    color: #fff;
    border-radius: 5px;
    z-index: 1003;
`;

const StyledLink = styled(RouterLink)`
    cursor: pointer;
    color: white;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;
