import { Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

const Information = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Background>
            <Container>
                <BlurContainer>
                    <ContentInfo>
                        <Typography color={'secondary'} fontSize={isMobile ? '18px' : '24px'}>Apalancamiento hasta
                        </Typography>
                        <Typography color={'secondary'} fontSize={isMobile ? '36px' : '54px'} fontWeight={'bold'}>1:200</Typography>
                    </ContentInfo>
                    <ContentInfo>
                        <Typography color={'secondary'} fontSize={isMobile ? '18px' : '24px'}>Protección de balance negativo</Typography>
                        <Typography color={'secondary'} fontSize={isMobile ? '36px' : '54px'} fontWeight={'bold'}>100%</Typography>
                    </ContentInfo>
                    <ContentInfo>
                        <Typography color={'secondary'} fontSize={isMobile ? '18px' : '24px'}>Instrumentos operables</Typography>
                        <Typography color={'secondary'} fontSize={isMobile ? '36px' : '54px'} fontWeight={'bold'}>+200</Typography>
                    </ContentInfo>
                </BlurContainer>
            </Container>
        </Background>
    )
}

export default Information

const Background = styled.div`
    padding: 50px 0;
    height: auto;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding-bottom: 2rem;
    }
`

const BlurContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.2); /* Color de fondo con transparencia */
    backdrop-filter: blur(5px); /* Aplicar el efecto blur */
    -webkit-backdrop-filter: blur(10px); /* Compatibilidad con Safari */
    padding: 20px; /* Espaciado interno (opcional) */
    width: calc(100% - 40px); /* Ancho del contenedor */
    height: 156px; /* Altura del contenedor */
    border-radius: 25px;
    border: 1px solid #00FFE0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        /* padding: 1rem 0; */
    }
`;

const ContentInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`