import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Card from './components/Card';
import CardMethod from './components/CardMethod';

const HowItWorksEn = () => {

    const cripto = window.location.origin + '/assets/svg/criptomoneda.svg';

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <BackgroundContent id="Como-funciona">
            <Container>
                <Content>
                    <Typography fontSize={isMobile ? 32 : 48} textAlign={'center'} fontWeight={'bold'}>How it works</Typography>
                    <Steps>
                        <Card title="Open an account" image='assets/images/account.png' description="Click the button below and follow our simple registration process." button={<Button variant='contained' color='info'>SIGN UP</Button>} />
                        <Card title="Deposit" image='assets/images/cash.png' description="Minimum deposit of 100 USD." />
                        <Card title="Trade" image='assets/images/notebook.png' description="Trade with any of the 100 assets and stocks." />
                        <Card title="Withdraw" image='assets/images/transfer.png' description="Transfer your funds easily. Withdrawals in 24 hours." canArrow={false} />
                    </Steps>
                </Content>
                <Methods>
                    <CardMethod title="Cryptocurrencies" icon={cripto} />
                </Methods>
            </Container>
        </BackgroundContent>
    );
};

export default HowItWorksEn;

const BackgroundContent = styled.div`
    background-color: #EEF8FF;
    position: relative;
    width: 100%;
    height: auto;
    padding: 2rem 0;
    @media (max-width: 768px) {
        padding: auto;
        max-width: auto;
        padding: 0 0 2rem 0;
    }
`;

const Content = styled.div`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
`;

const Steps = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-top: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding: auto;
    }
`;

const Methods = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-top: 2rem;
    @media (max-width: 768px) {
        gap: 6px;
        flex-direction: column;
    }
`;
