import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import Information from '../information/Information';

const Banner = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Background id='Home'>
            <ContainerData>
                <Texts>
                    <Typography fontSize={isMobile ? '38px' : '72px'} color={'secondary'} lineHeight={.5} fontWeight={600}>Un broker <br /> creado por
                        <Typography fontSize={isMobile ? '38px' : '72px'} color={'primary'} component="span" fontWeight={600}> traders </Typography>
                        <br /> para traders
                    </Typography>
                    <br />
                    <br />
                    <Typography fontSize={isMobile ? '18px' : '26px'} color={'secondary'}>Encuentra en un solo lugar todo <br /> lo que necesitas para operar.</Typography>
                    <br />
                    <Typography fontSize={isMobile ? '18px' : '26px'} color={'secondary'} fontWeight={600}>CREÁ TU CUENTA SIN COSTOS</Typography>
                    <br />
                    <Buttons>
                        <ButtonFake size='large' variant='contained' noBorder={true}>REGISTRATE</ButtonFake>
                        <ButtonFake size='large' variant='outlined'>DEMO</ButtonFake>
                    </Buttons>
                </Texts>
                <ImagePhone src={isMobile ? 'assets/images/cel_mobile.png' : 'assets/images/cel_desktop.png'} />
            </ContainerData>
            <Information />
        </Background>
    )
}

export default Banner

const Background = styled.div`
    height: auto;
    width: 100%;
    background: rgb(2, 194, 180); /* Color de fondo sólido de respaldo */
    background: linear-gradient(28deg, rgba(2, 194, 180, 1) 0%, rgba(3, 153, 151, 1) 8%, rgba(4, 92, 107, 1) 25%, rgba(5, 51, 77, 1) 45%, rgba(7, 41, 62, 1) 62%, rgba(11, 17, 25, 1) 100%);
    padding-top: 60px;

    @media (max-width: 768px) {
        width: 100% !important;
        background-size: auto;
        height: auto;
        background-position: left;
        padding-top: 20px;
    }
`

const ContainerData = styled(Container)`
    display: flex !important;
    height: 75vh;
    @media (max-width: 768px) {
        width: 100% !important;
        height: auto;
        flex-direction: column-reverse;
    }
`

const Texts = styled.div`
    width: 70%;
    display: flex !important;
    /* height: 75vh; */
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
        /* align-items: center; */
        width: 100%;
        text-align: left;
        height: auto;
    }
`

const Buttons = styled.div`
    display: flex;
    gap: 12px;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100% !important;
    }
`

const ButtonFake = styled(Button)`
    width: 160px;
    height: 50px;
    font-size: 16px !important;
    border: ${p => p.noBorder && 'none'} !important;
    @media (max-width: 768px) {
        width: 100% !important;
        height: auto;
    }
`

const ImagePhone = styled.img`
    width: auto;
    height: 95%;
    position: absolute;
    top: 90px;
    right: 200px;
    @media (max-width: 768px) {
        width: 100% !important;
        height: auto;
        position: static;
    }
`