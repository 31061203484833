/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const CardEn = (props) => {
    const { title, subtitle, currencyArray } = props;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animatedNumber, setAnimatedNumber] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % currencyArray.length);
        }, 5000); // Change every 5 seconds

        return () => clearInterval(intervalId); // Clean up the interval on unmount
    }, [currencyArray.length]);

    useEffect(() => {
        if (currencyArray[currentIndex]) {
            const numberStr = currencyArray[currentIndex].numbers;

            const animateDigits = numberStr.split('').map((char, idx) => {
                if (/[0-9]/.test(char)) {
                    return animateDigit(char, idx);
                }
                return char; // Keep other characters intact
            });

            setAnimatedNumber('');
            Promise.all(animateDigits).then(results => {
                setAnimatedNumber(results.join(''));
            });
        }
    }, [currentIndex, currencyArray]);

    const animateDigit = (finalDigit, idx) => {
        return new Promise(resolve => {
            let currentDigit = Math.floor(Math.random() * 10);
            const iterations = 10;
            let counter = 0;

            const interval = setInterval(() => {
                currentDigit = (currentDigit + 1) % 10;
                const updatedNumber = animatedNumber.split('').map((char, i) => (i === idx ? currentDigit : char)).join('');
                setAnimatedNumber(updatedNumber);

                counter++;
                if (counter >= iterations) {
                    clearInterval(interval);
                    resolve(finalDigit);
                }
            }, 100);
        });
    };

    return (
        <Container>
            <Typography color={'secondary'} fontSize={48}>{title}</Typography>
            <Typography color={'GrayText'}>{subtitle}</Typography>
            <ContainerCurrency>
                <FadeInContainer key={currentIndex}>
                    <Typography color={'secondary'} fontSize={72}>{animatedNumber}
                        <Typography fontSize={28} component={'span'}>PIPS</Typography>
                    </Typography>
                    <Typography color={'secondary'} fontSize={36}>{currencyArray[currentIndex]?.currency}</Typography>
                </FadeInContainer>
            </ContainerCurrency>
            <ContainerInfo>
                <Info>
                    <Typography color={'GrayText'}>{'Minimum Deposit'}</Typography>
                    <Typography color={'GrayText'}>{'100USD'}</Typography>
                </Info>
                <Info>
                    <Typography color={'GrayText'}>{'Max Leverage'}</Typography>
                    <Typography color={'GrayText'}>{'1:200'}</Typography>
                </Info>
            </ContainerInfo>
            <Button variant='outlined' color='primary' size='large'>OPEN ACCOUNT</Button>
        </Container>
    );
};

export default CardEn;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const FadeInContainer = styled.div`
    animation: ${fadeIn} 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Container = styled.div`
    width: 364px;
    height: 496px;
    border-radius: 25px;
    border: 1px solid #00FFE0;
    background-color: #0B1119;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    @media (max-width: 768px) {
        height: auto;
        width: 100%;
        padding-bottom: 1.5rem;
        padding-top: 1rem;
        button {
            width: calc(100% - 3rem);
        }
    }
`;

const Info = styled.div`
    width: 70%;
    display: flex;
    justify-content: space-between;
`;

const ContainerInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding-bottom: 1rem;
    }
`;

const ContainerCurrency = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
